import React, { useState, useContext } from "react";
import styles from "./AddNewPage.module.css";
/* Third party */
import { Helmet } from "react-helmet";
import ImageUploading from "react-images-uploading";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
/* Custom Hook */

/* Context */
import { LoadingContext } from "../../../store/LoadingProvider";
import { TextEditorContext } from "../../../store/TextEditorProvider";
import { RolePermissionsContext } from "../../../store/RolePermissionsProvider";
/* Components */
import { TextEditor } from "../../TextEditor";
/* Custom Button */
import Button from "../../../UI/Button/Button";
/* MUI */
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import { CloseOutlined } from "@mui/icons-material";
/* Icons */
import { IoMdCloseCircleOutline } from "react-icons/io";
import { Arrow, WriteIcon } from "../../../assets/Icons";
import { useAddNewPageMutation } from "../../../RTK/apiSlices/pagesApi";
import {
	useAdminPageCategoriesQuery,
	useAdminPostCategoriesQuery,
} from "../../../RTK/apiSlices/selectorApi";

const BackDrop = ({ onClick }) => {
	return (
		<div
			onClick={onClick}
			className='fixed top-0 left-0 z-10 w-full h-full opacity-50 back_drop bg-slate-900'></div>
	);
};

const AddNewPage = () => {
	const navigate = useNavigate();
	const token = localStorage.getItem("token");

	const [tag, setTag] = useState("");
	const [images, setImages] = useState([]);
	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;
	const editorContent = useContext(TextEditorContext);
	const { editorValue, setEditorValue } = editorContent;
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	const [descriptionLength, setDescriptionLength] = useState(false);

	const { data: pageCategory } = useAdminPageCategoriesQuery();
	const { data: postCategory } = useAdminPostCategoriesQuery();

	const [page, setPage] = useState({
		title: "",
		page_desc: "",
		page_content: "",
		seo_title: "",
		seo_link: "",
		seo_desc: "",
		tags: [],
		pageCategory: [],
		postcategory_id: "",
		image: "",
	});
	const {
		handleSubmit,
		control,
		formState: { errors },
		reset,
	} = useForm({
		mode: "onBlur",
		defaultValues: {
			title: "",
			page_desc: "",
			seo_title: "",
			seo_link: "",
			seo_desc: "",
		},
	});
	// -------------------------------------------------------

	// check if the category of page is post
	const itsPost = page?.pageCategory?.includes(1);
	// -----------------------------------------------------------

	// Errors
	const [DataError, setDataError] = useState({
		title: "",
		page_content: "",
		page_desc: "",
		seo_title: "",
		seo_link: "",
		seo_desc: "",
		tags: "",
		pageCategory: "",
		postcategory_id: "",
		image: "",
	});
	const resetData = () => {
		setDataError({
			title: "",
			page_content: "",
			page_desc: "",
			seo_title: "",
			seo_link: "",
			seo_desc: "",
			tags: "",
			pageCategory: "",
			postcategory_id: "",
			image: "",
		});
	};
	// ------------------------------------------

	const addTags = () => {
		setPage({ ...page, tags: [...page.tags, tag] });
		setTag("");
	};

	const updateTags = (i) => {
		const newTags = page?.tags?.filter((tag, index) => index !== i);
		setPage({ ...page, tags: newTags });
	};
	/**
	 * --------------------------------------------------------------
	 * Add Post image
	 * ------------------------------------------
	 * handle images size
	 **/

	const maxFileSize = 1 * 1024 * 1024; // 1 MB;
	const errMsgStyle = {
		whiteSpace: "normal",
		padding: "0",
		fontSize: "14px",
	};
	const onChange = (imageList, addUpdateIndex) => {
		// Check image size before updating state
		const isSizeValid = imageList.every(
			(image) => image.file.size <= maxFileSize
		);

		const requireMindWidth = 300;
		const requireMaxdWidth = 600;
		const requireMindHeight = 150;
		const requireMaxdHeight = 300;

		// Errors messages
		const errorMessage = "حجم الصورة يجب أن لا يزيد عن 1 ميجابايت.";
		const errorMes = `
				<span> - الحد الأدني للأبعاد هو 300بكسل عرض و 150بكسل ارتفاع</span>
				 <br />
				<span> - الحد الأقصي للأبعاد هو 600بكسل عرض و 300بكسل ارتفاع</span> `;

		// Check if the image dimensions are valid
		imageList?.every((image) => {
			const img = new Image();

			// Set the event listener to check dimensions once the image is loaded
			img.onload = () => {
				const isDimensionsValid =
					img.width >= requireMindWidth &&
					img.width <= requireMaxdWidth &&
					img.height >= requireMindHeight &&
					img.height <= requireMaxdHeight;

				if (!isDimensionsValid && isSizeValid) {
					toast.warning(
						<div
							className='wrign-dimensions'
							style={errMsgStyle}
							dangerouslySetInnerHTML={{ __html: errorMes }}
						/>,
						{
							theme: "light",
						}
					);
					setImages([]);
					setDataError({
						...DataError,
						image: (
							<div
								className='wrign-dimensions'
								style={errMsgStyle}
								dangerouslySetInnerHTML={{ __html: errorMes }}
							/>
						),
					});
				} else if (!isSizeValid && !isDimensionsValid) {
					toast.warning(errorMessage, {
						theme: "light",
					});

					toast.warning(
						<div
							className='wrign-dimensions'
							style={errMsgStyle}
							dangerouslySetInnerHTML={{ __html: errorMes }}
						/>,
						{
							theme: "light",
						}
					);
					setImages([]);
				} else if (!isSizeValid && isDimensionsValid) {
					toast.warning(errorMessage, {
						theme: "light",
					});
					setImages([]);
					setDataError({
						...DataError,
						image: errorMessage,
					});
				} else {
					// If all checks are valid, update the state
					setImages(imageList);
					setPage({ ...page, image: imageList[0].data_url });

					setDataError({
						...DataError,
						image: "",
					});
				}
			};
			img.src = image?.data_url;
			return true; // Returning true because the actual check is done in the onload event
		});
	};
	// ---------------------------------------------

	const [addNewPage] = useAddNewPageMutation();

	const addNewPageHandel = async (formData) => {
		try {
			const res = await addNewPage({
				body: formData,
			});
			if (res?.data?.success === true && res?.data?.data?.status === 200) {
				setLoadingTitle(null);
				navigate("/pages");

				setEditorValue("");
			} else {
				setLoadingTitle(null);

				setDataError({
					title: res?.data?.message?.en?.title?.[0],
					page_content: res?.data?.message?.en?.page_content?.[0],
					page_desc: res?.data?.message?.en?.page_desc?.[0],
					seo_title: res?.data?.message?.en?.seo_title?.[0],
					seo_link: res?.data?.message?.en?.seo_link?.[0],
					seo_desc: res?.data?.message?.en?.seo_desc?.[0],
					tags: res?.data?.message?.en?.tags?.[0],
					pageCategory: res?.data?.message?.en?.pageCategory?.[0],
					postcategory_id: res?.data?.message?.en?.postcategory_id?.[0],
					image: res?.data?.message?.en?.image?.[0],
				});
				toast.error(res?.data?.message?.en?.title?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.page_content?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.page_desc?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.seo_title?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.seo_link?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.seo_desc?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.tags?.[0], { theme: "light" });
				toast.error(res?.data?.message?.en?.pageCategory?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.postcategory_id?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message, {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.image?.[0], { theme: "light" });
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handlePage = (dataPage) => {
		resetData();
		let formData = new FormData();
		formData.append("title", dataPage?.title);
		formData.append("page_content", editorValue);
		formData.append("page_desc", dataPage?.page_desc);
		formData.append("seo_title", dataPage?.seo_title);

		formData.append("seo_desc", dataPage?.seo_desc);
		formData.append(
			"tags",
			page?.tags?.length === 0 ? "" : page?.tags?.join(",")
		);
		if (page?.pageCategory?.length > 0) {
			for (let i = 0; i < page?.pageCategory?.length; i++) {
				formData.append([`pageCategory[${i}]`], page?.pageCategory[i]);
			}
		}

		formData.append(
			"postcategory_id",
			itsPost ? page?.postcategory_id || "" : null
		);
		if (images.length !== 0) {
			formData.append("image", itsPost ? images[0]?.file || "" : null);
		}

		setLoadingTitle("جاري حفظ الصفحة");
		addNewPageHandel(formData);
	};

	return (
		<>
			<Helmet>
				<title>لوحة تحكم سوق نيوم | انشاء صفحة</title>
			</Helmet>
			<BackDrop
				onClick={() => {
					navigate("/pages");
					setEditorValue("");
				}}
			/>
			<form onSubmit={handleSubmit(handlePage)} className='h-full'>
				<div
					className='absolute z-20 flex flex-col overflow-hidden rounded-lg top-5 translate-x-2/4 add_new_page_popup right-2/4 bg-[#EEF3FC]'
					style={{ width: "72.5rem", maxWidth: "90%" }}>
					<div className='flex items-center justify-between w-full h-16 px-4 py-4 trader_alert'>
						<h2 className='text-xl font-medium text-center text-[#1948a0]'>
							انشاء صفحة
						</h2>
						<IoMdCloseCircleOutline
							size={"1.25rem"}
							color={"#1948a0"}
							className={"cursor-pointer"}
							onClick={() => {
								navigate("/pages");
								setEditorValue("");
							}}
						/>
					</div>
					<div className='md:p-6 p-4 rounded-b-lg  bg-[#fff]'>
						<div className='flex flex-col'>
							<div
								className='flex gap-4 p-4 items-center rounded-sm md:h-14 h-[45px]'
								style={{
									border: "1px solid #D3D3D3",
								}}>
								<WriteIcon fill='#ADB5B9'></WriteIcon>
								<Controller
									name={"title"}
									control={control}
									rules={{
										required: "حقل العنوان مطلوب",
										pattern: {
											value: /^[^-\s][\u0600-\u06FF-A-Za-z0-9 ]+$/i,
											message:
												"يجب أن يكون العنوان عبارة عن نصاّّ ولا يحتوي علي حروف خاصه مثل الأقوس والرموز",
										},
									}}
									render={({ field: { onChange, value } }) => (
										<input
											className='w-full outline-none'
											placeholder={"عنوان الصفحة*"}
											type='text'
											name='title'
											value={value}
											onChange={(e) => {
												setPage({
													...page,
													title: e.target.value,
												});
												onChange(e);
											}}
										/>
									)}
								/>
							</div>
							<span className='text-red-500 text-[16px]'>
								{DataError?.title}
								{errors?.title && errors.title.message}
							</span>
							<div
								className='flex items-center gap-4 p-4 mt-4 rounded-sm md:mt-10'
								style={{
									border: "1px solid #D3D3D3",
								}}>
								<Controller
									name={"page_desc"}
									control={control}
									rules={{
										required: "حقل وصف الصفحة مطلوب",
									}}
									render={({ field: { onChange, value } }) => (
										<textarea
											name='page_desc'
											className='w-full outline-none'
											placeholder='اكتب وصف قصير للصفحة لا يتجأوز  100 حرف'
											rows={5}
											value={value}
											onChange={(e) => {
												if (e.target.value.length <= 100) {
													onChange(e.target.value.substring(0, 100));
													setDescriptionLength(false);
													setPage({
														...page,
														page_desc: e.target.value.substring(0, 100),
													});
												} else {
													setDescriptionLength(true);
												}
											}}></textarea>
									)}
								/>
							</div>
							<span className='text-red-500 text-[16px]'>
								{DataError?.page_desc}
								{errors?.page_desc && errors.page_desc.message}
							</span>
							{descriptionLength && (
								<span className='text-red-500 text-[16px]'>
									الوصف يجب ان لا يتجاوز 100 حرف
								</span>
							)}
							<div className={`${styles.text} md:mt-10 mt-4`}>
								<TextEditor
									ToolBar={"createOrEditPages"}
									placeholder={"محتوى الصفحة..."}
								/>
								<span className='text-red-500 text-[16px]'>
									{DataError?.page_content}
								</span>
							</div>
							<div className='mt-4 md:mt-10'>
								<h2 className='text-xl font-semibold'>تحسينات SEO</h2>
								<div className='mt-3 md:mt-5'>
									<h2 className='md:text-lg text-[16px] font-medium'>
										عنوان صفحة تعريفية (Page Title)
									</h2>
									<div
										className='md:h-14 h-[45px] flex gap-4 mt-3 p-4 items-center'
										style={{
											border: "1px solid #D3D3D3",
										}}>
										<WriteIcon fill='#ADB5B9'></WriteIcon>
										<Controller
											name={"seo_title"}
											control={control}
											render={({ field: { onChange, value } }) => (
												<input
													className='flex-1 outline-none '
													placeholder={"عنوان صفحة تعريفية (Page Title)"}
													type='text'
													name='seo_title'
													value={value}
													onChange={(e) => {
														setPage({
															...page,
															seo_title: e.target.value,
														});
														onChange(e);
													}}
												/>
											)}
										/>
									</div>
									<span className='text-red-500 text-[16px]'>
										{DataError?.seo_title}
										{errors?.seo_title && errors.seo_title.message}
									</span>
								</div>
								<div className='mt-3 md:mt-5'>
									<h2 className='md:text-lg text-[16px] font-medium'>
										رابط صفحة تعريفية (SEO Page URL){" "}
										<span className='text-sm text-red-600 '>(تلقائي)</span>
									</h2>
									<div
										className='md:h-14 h-[45px] flex gap-4 mt-3 p-4 items-center'
										style={{
											border: "1px solid #D3D3D3",
										}}>
										<WriteIcon fill='#ADB5B9'></WriteIcon>
										<Controller
											name={"seo_link"}
											control={control}
											render={({ field: { onChange, value } }) => (
												<input
													style={{
														direction: "ltr",
													}}
													readOnly
													className='flex-1 text-right outline-none '
													placeholder={"يتم اضافه الرابط تلقائي"}
													type='text'
													name='seo_link'
													value={value}
													onChange={(e) => {
														setPage({
															...page,
															seo_link: e.target.value,
														});
														onChange(e);
													}}
												/>
											)}
										/>
									</div>
									<span className='text-red-500 text-[16px]'>
										{DataError?.seo_link}
										{errors?.seo_link && errors.seo_link.message}
									</span>
								</div>
								<div className='mt-3 md:mt-5'>
									<h2 className='md:text-lg text-[16px] font-medium'>
										وصف صفحة تعريفية (Page Description)
									</h2>
									<div
										className='md:h-14 h-[45px] flex gap-4 mt-3 p-4 items-center'
										style={{
											border: "1px solid #D3D3D3",
										}}>
										<WriteIcon fill='#ADB5B9'></WriteIcon>
										<Controller
											name={"seo_desc"}
											control={control}
											render={({ field: { onChange, value } }) => (
												<input
													className='flex-1 outline-none '
													placeholder={"وصف صفحة تعريفية (Page Description)"}
													type='text'
													name='seo_desc'
													value={value}
													onChange={(e) => {
														setPage({
															...page,
															seo_desc: e.target.value,
														});
														onChange(e);
													}}
												/>
											)}
										/>
									</div>
									<span className='text-red-500 text-[16px]'>
										{DataError?.seo_desc}
										{errors?.seo_desc && errors.seo_desc.message}
									</span>
								</div>
							</div>
							<div className='flex flex-col gap-4 mt-4 md:mt-10 md:flex-row'>
								<div
									className='flex-1 rounded-lg '
									style={{
										border: "1px solid #ECECEC",
									}}>
									<h2
										className='py-4 px-5 md:text-lg text-[16px] font-medium '
										style={{
											color: "#1948a0",
											border: "1px solid #ECECEC",
										}}>
										تصنيف الصفحة
									</h2>
									<div className='overflow-y-auto h-[auto] max-h-[12rem]'>
										<FormGroup className='pl-3'>
											{pageCategory?.categories?.map((cat, index) => (
												<FormControlLabel
													value={cat?.id}
													key={index}
													sx={{
														py: 1,
														mr: 0,
														pr: 2,
														borderBottom: "1px solid #ECECEC",
														"& .MuiTypography-root": {
															fontSize: "18px",
															fontWeight: "500",
															"@media(max-width:767px)": {
																fontSize: "16px",
															},
														},
													}}
													control={
														<Checkbox
															checked={
																page?.pageCategory?.includes(cat?.id) || false
															}
															onChange={(e) => {
																if (e.target.checked) {
																	setPage((prevPage) => ({
																		...prevPage,
																		pageCategory: [
																			...prevPage.pageCategory,
																			parseInt(e.target.value),
																		],
																	}));
																} else {
																	setPage((prevPage) => ({
																		...prevPage,
																		pageCategory: prevPage.pageCategory.filter(
																			(item) =>
																				parseInt(item) !== parseInt(cat.id)
																		),
																	}));
																}
															}}
															sx={{ "& path": { fill: "#000000" } }}
														/>
													}
													label={cat?.name}
												/>
											))}
										</FormGroup>
									</div>
									<span className='text-red-500 text-[16px]'>
										{DataError?.pageCategory}
									</span>
								</div>
								<div
									className='flex-1 overflow-hidden rounded-lg'
									style={{
										height: "auto",
										maxHeight: "16.5rem",

										border: "1px solid #ECECEC",
									}}>
									<h2
										className='py-4 px-5 md:text-lg text-[16px] font-medium'
										style={{
											color: "#1948a0",
											border: "1px solid #ECECEC",
										}}>
										كلمات مفتاحية
									</h2>
									<div className='flex gap-4 p-3 md:mt-8 md:px-3'>
										<Button
											btnType={"button"}
											onClick={addTags}
											className='md:h-14 h-[45px] font-medium md:text-lg text-[16px]'
											style={{ minWidth: "fit-content" }}
											type={"outline"}>
											اضافة
										</Button>
										<input
											value={tag}
											onChange={(e) => setTag(e.target.value)}
											className='outline-none flex-1 rounded-lg md:h-14 h-[45px]'
											style={{
												border: "1px solid #707070",
												paddingRight: "20px",
											}}
											type='text'
										/>
									</div>
									<div className='p-3'>
										<div className={styles.tags_boxes}>
											{page?.tags?.map((tag, index) => (
												<div key={index} className={styles.tag}>
													<CloseOutlined onClick={() => updateTags(index)} />
													<span>{tag}</span>
												</div>
											))}
										</div>
									</div>
									<div className='px-3'>
										<span className='text-red-500 text-[16px]'>
											{DataError?.tags}
										</span>
									</div>
								</div>
							</div>
							{itsPost && (
								<div className='flex flex-col items-start gap-4 mt-4 md:flex-row md:mt-6'>
									<div
										className='flex flex-col w-full rounded-lg'
										style={{
											height: "auto",
											maxHeight: "16.5rem",

											border: "1px solid #ECECEC",
										}}>
										<label
											style={{
												color: "#1948a0",
												border: "1px solid #ECECEC",
											}}
											className='md:text-[18px] text-[16px]  text-[#1948a0]py-4 px-5 md:text-lg font-medium'>
											تصنيف المدونة <span className='text-red-500'>*</span>
										</label>
										<FormControl className='w-[555px] md:h-[56px] h-[44px] max-w-full'>
											<Select
												className={styles.select}
												value={page?.postcategory_id}
												onChange={(e) => {
													setPage({
														...page,
														postcategory_id: e.target.value,
													});
												}}
												displayEmpty
												IconComponent={(props) => (
													<Arrow fill='#242424' {...props} />
												)}
												inputProps={{ "aria-label": "Without label" }}
												renderValue={(selected) => {
													if (
														page?.postcategory_id === "" ||
														page?.postcategory_id === undefined
													) {
														return <h2>اختر التصنيف</h2>;
													}
													const result =
														postCategory?.categories?.filter(
															(item) => item?.id === parseInt(selected)
														) || page?.postcategory_id;
													return result[0]?.name;
												}}
												sx={{
													height: "3.5rem",
													backgroundColor: "#EEF4FC",
													border: "none",
													borderRadius: "none",
													"& .MuiOutlinedInput-notchedOutline": {
														border: "none",
													},
												}}>
												{postCategory?.categories?.map((item, idx) => {
													return (
														<MenuItem
															key={idx}
															className='souq_storge_category_filter_items'
															sx={{
																backgroundColor: "#EFF9FF",
																height: "3rem",
																"&:hover": {},
															}}
															value={`${item?.id}`}>
															{item?.name}
														</MenuItem>
													);
												})}
											</Select>
										</FormControl>
										<span className='text-red-500 text-[16px]'>
											{DataError?.postcategory_id}
										</span>
									</div>
									<div
										className='flex flex-col w-full gap-4 rounded-lg'
										style={{
											border: "1px solid #ECECEC",
										}}>
										<div
											className='flex flex-row flex-wrap items-center gap-2 px-5 py-4 font-medium md:text-lg'
											style={{
												color: "#1948a0",
												border: "1px solid #ECECEC",
											}}>
											<label className='md:text-[18px] text-[16px]'>
												صورة المدونة <span className='text-red-500'>*</span>
											</label>
											<span className='text-gray-400 text-[14px] font-normal'>
												(الحد الأقصى للصورة 1MB)
											</span>
										</div>
										<div className='py-1 pr-5 '>
											<span
												style={{
													display: "block",
													fontSize: "1rem",
													color: "#7e7e7e",
													whiteSpace: "break-spaces",
													fontWight: "600",
												}}>
												الأبعاد المناسبة:
											</span>
											<span
												style={{
													fontSize: "0.9rem",
													color: "#7e7e7e",

													whiteSpace: "break-spaces",
												}}>
												- (الحد الادني للابعاد 300 بكسل عرض - 150 بكسل ارتفاع)
											</span>
											<span
												style={{
													display: "block",
													fontSize: "0.9rem",
													color: "#7e7e7e",

													whiteSpace: "break-spaces",
												}}>
												- (الحد الأقصي للابعاد 600 بكسل عرض - 300 بكسل ارتفاع)
											</span>
										</div>

										<ImageUploading
											value={images}
											onChange={onChange}
											maxNumber={2}
											dataURLKey='data_url'
											acceptType={["jpg", "png", "jpeg"]}>
											{({ imageList, onImageUpload, dragProps }) => (
												// write your building UI
												<div className='w-full md:h-[56px] h-[44px] max-w-full px-4'>
													<div
														className='relative overflow-hidden upload__image-wrapper'
														style={{
															border: "1px solid #F0F0F0",
															borderRadius: "6px",
														}}
														onClick={() => {
															onImageUpload();
														}}
														{...dragProps}>
														<div
															className='image-item w-full flex cursor-pointer md:h-[56px] h-[44px]'
															style={{ backgroundColor: "#FFFFFF" }}>
															{!images[0] && (
																<div className='flex flex-row items-center justify-between w-full h-full py-4 pr-5'>
																	<h2
																		className='md:text-[18px] text-[14px]'
																		style={{ color: "#7C7C7C" }}>
																		( اختر صورة فقط png & jpg & jpeg )
																	</h2>
																	<div
																		className='flex flex-col justify-center items-center md:px-10 px-5 rounded-md md:text-[18px] text-[14px] md:h-[56px] h-[44px]'
																		style={{
																			backgroundColor: "#A7A7A7",
																			color: "#ffffff",
																		}}>
																		استعراض
																	</div>
																</div>
															)}
															{images[0] && (
																<div className='flex flex-row items-center justify-between w-full h-full py-4 pr-5'>
																	<h2
																		style={{
																			color: "#7C7C7C",
																			maxWidth: "259px",
																		}}
																		className=' text-ellipsis'>
																		{images[0].file.name}
																	</h2>
																	<div
																		className='flex flex-col justify-center items-center md:px-10 px-5 rounded-lg md:text-[18px] text-[14px] md:h-[56px] h-[44px]'
																		style={{
																			backgroundColor: "#A7A7A7",
																			color: "#ffffff",
																		}}>
																		استعراض
																	</div>
																</div>
															)}
														</div>
													</div>
												</div>
											)}
										</ImageUploading>
										{page?.image !== "" && (
											<div className='flex items-center justify-center w-full px-5 mt-4 overflow-hidden rounded-lg'>
												<img
													className='rounded-lg'
													src={page?.image}
													alt='edit-img'
													loading='lazy'
												/>
											</div>
										)}

										<span className='text-red-500 text-[16px] pr-5 pb-4'>
											{DataError?.image}
										</span>
									</div>
								</div>
							)}
							<div className='flex items-center justify-center gap-4 my-8 md:my-20 md:gap-8'>
								{permissions?.includes("admin.page.store") && (
									<Button
										btnType={"submit"}
										className='md:h-14 save_button h-[45px] md:w-[109px] w-full'
										fontSize={"md:text-2xl text-[18px] font-normal"}
										style={{ minWidth: "fit-content" }}
										type={"normal"}>
										حفظ
									</Button>
								)}
								<Button
									btnType={"submit"}
									style={{ borderColor: "#f75e22", minWidth: "fit-content" }}
									textStyle={{ color: "#f75e22" }}
									className='md:h-14 h-[45px] md:w-[109px] w-full'
									fontSize={"md:text-2xl text-[18px] font-normal"}
									type={"outline"}
									onClick={() => {
										navigate("/pages");
										setEditorValue("");
									}}>
									الغاء
								</Button>
							</div>
						</div>
					</div>
					<div className='my-20'></div>
				</div>
			</form>
		</>
	);
};

export default AddNewPage;
