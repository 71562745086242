import React, { useState, useEffect, useContext } from "react";
import styles from "./ShowVerificationData.module.css";
/* Third party */
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
/* Components */
import CircularLoading from "../../../../UI/CircularLoading/CircularLoading";
/* Custom Button */
import Button from "../../../../UI/Button/Button";
/* Icons */
import { IoMdCloseCircleOutline } from "react-icons/io";
import { PDF, PhoneIcon } from "../../../../assets/Icons/index";
import VerificationModal from "../VerificationModal/VerificationModal";
import {
	useAcceptVerificationByIdMutation,
	useGetVerificationDataQuery,
	useRejectVerificationByIdMutation,
} from "../../../../RTK/apiSlices/verificationApi";
import { RolePermissionsContext } from "../../../../store/RolePermissionsProvider";

const BackDrop = ({ onClick }) => {
	return (
		<div
			onClick={onClick}
			className='fixed top-0 left-0 z-10 w-full h-full back_drop bg-slate-900 opacity-70'></div>
	);
};

const ShowVerificationData = () => {
	const { id } = useParams();
	const navigate = useNavigate();

	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;

	const { data: fetchedData, isLoading: loading } = useGetVerificationDataQuery(
		{ verificationId: id }
	);

	const [verification, setVerification] = useState(null);
	const [btnLoading, setBtnLoading] = useState(false);
	const [data, setData] = useState({
		store_name: "",
		store_activity: [],
		store_sub_activity: [],
		owner_name: "",
		verification_code: "",
		domain: "",
		domain_type: "",
		email: "",
		phonenumber: "",
		file: "",
		verification_type: "",
	});
	// --------------------------------------------

	useEffect(() => {
		setData({
			...data,
			email: fetchedData?.store?.user?.email,
			store_name: fetchedData?.store?.store_name,
			verification_code: fetchedData?.store?.verification_code,
			owner_name:
				fetchedData?.store?.verification_type === "maeruf"
					? fetchedData?.store?.owner_name
					: fetchedData?.store?.commercial_name,
			store_activity: fetchedData?.store?.activity || [],
			store_sub_activity: fetchedData?.store?.subcategory || [],
			phonenumber: fetchedData?.store?.user?.phonenumber?.startsWith("+966")
				? fetchedData?.store?.user?.phonenumber?.slice(4)
				: fetchedData?.store?.user?.phonenumber?.startsWith("00966")
				? fetchedData?.store?.user?.phonenumber?.slice(5)
				: fetchedData?.store?.user?.phonenumber,
			verification_type: fetchedData?.store?.verification_type || "",
			domain: fetchedData?.store?.domain || "",
			domain_type: fetchedData?.store?.domain_type || "",
			file: fetchedData?.store?.file,
		});
	}, [fetchedData?.store]);

	const [acceptVerificationById] = useAcceptVerificationByIdMutation();
	const acceptVerificationByIdHandel = async (verificationId) => {
		try {
			await acceptVerificationById({ verificationId })
				.unwrap()
				.then((data) => {
					if (data.success) {
						setVerification("accepted");

						setBtnLoading(false);
					} else {
						toast.error(data?.message?.ar, {
							theme: "light",
						});
						navigate("/verification");

						setBtnLoading(false);
					}
				});
		} catch (err) {
			console.error("Failed to delete the category", err);
		}
	};

	const acceptVerification = () => {
		setBtnLoading(true);
		acceptVerificationByIdHandel(id);
	};

	const [rejectVerificationById] = useRejectVerificationByIdMutation();
	const rejectVerificationByIdHandel = async (verificationId) => {
		try {
			await rejectVerificationById({ verificationId })
				.unwrap()
				.then((data) => {
					if (data.success) {
						setVerification("rejected");

						setBtnLoading(false);
					} else {
						toast.error(data?.message?.ar, {
							theme: "light",
						});
						navigate("/verification");

						setBtnLoading(false);
					}
				});
		} catch (err) {
			console.error("Failed to delete the category", err);
		}
	};

	// reject verification function
	const rejectVerification = () => {
		setBtnLoading(true);
		rejectVerificationByIdHandel(id);
	};

	if (verification === "accepted") {
		return (
			<VerificationModal
				title={"سيتم إبلاغ المالك بقبول توثيق متجره"}
				type={"accepted"}
				cancel={() => {
					setVerification(null);
					navigate("/verification");
				}}
			/>
		);
	} else if (verification === "rejected") {
		return (
			<VerificationModal
				title={"سيتم إبلاغ المالك برفض توثيق متجره"}
				type={"rejected"}
				cancel={() => {
					setVerification(null);
					navigate("/verification");
				}}
			/>
		);
	}

	const hasAcceptVerificationPermission = permissions?.includes(
		"admin.verification.acceptVerification"
	);
	const hasRejectVerificationPermission = permissions?.includes(
		"admin.verification.rejectVerification"
	);

	return (
		<>
			<BackDrop
				onClick={() => {
					navigate("/verification");
				}}
			/>
			<div
				className='fixed flex flex-col top-[15%] translate-x-2/4  right-2/4 z-20 rounded-lg overflow-hidden'
				style={{ width: "60.25rem", maxWidth: "90%" }}>
				<div
					className='flex items-center justify-between w-full h-16 px-4 py-4 trader_alert'
					style={{
						backgroundColor: "#fc8d5d",
					}}>
					<h2
						style={{ color: "#ECFEFF" }}
						className='md:text-[22px] text-[18px] font-medium text-center flex-1'>
						بيانات طلب توثيق متجر
					</h2>
					<IoMdCloseCircleOutline
						width='20px'
						height='20px'
						size={"1.25rem"}
						color={"#fff"}
						className={"cursor-pointer"}
						onClick={() => {
							navigate("/verification");
						}}
					/>
				</div>
				<div className='max-h-[450px] flex-1 flex flex-col md:px-[98px] md:py-[48px] px-4 py-[30px] gap-[24px] bg-white text-right overflow-y-auto'>
					{loading ? (
						<div className='w-full flex flex-col items-center justify-center min-h-[50vh]'>
							<CircularLoading />
						</div>
					) : (
						<>
							<div className='flex flex-col gap-[10px]'>
								<label
									style={{ color: "#011723" }}
									className='md:text-[20px] text-[16px]'
									htmlFor='activity_store'>
									نشاط أو تصنيف المتجر الرئيسي
								</label>
								{data?.store_activity?.length !== 0 ? (
									<div className='bg-[#fafafa] w-full  min-h-[56px] max-h-48  max-w-full py-4 px-5 rounded-md overflow-auto flex flex-wrap justify-start items-center gap-1'>
										{data?.store_activity?.map((category) => (
											<div
												key={category?.id}
												className='px-2 bg-white rounded '>
												{category?.name}
											</div>
										))}
									</div>
								) : (
									<div className='flex justify-center items-center text-[16px] text-red-600'>
										لاتوجد بيانات
									</div>
								)}
							</div>
							<div className='flex flex-col gap-[10px]'>
								<label
									style={{ color: "#011723" }}
									className='md:text-[20px] text-[16px]'
									htmlFor='activity_store'>
									نشاط أو تصنيف المتجر الفرعي
								</label>
								{data?.store_sub_activity?.length !== 0 ? (
									<div className='bg-[#fafafa] w-full min-h-[56px] max-h-48   max-w-full py-4 px-5 rounded-md overflow-auto flex flex-wrap justify-start items-center gap-1'>
										{data?.store_sub_activity?.map((category) => (
											<div
												key={category?.id}
												className='px-2 bg-white rounded '>
												{category?.name}
											</div>
										))}
									</div>
								) : (
									<div className='flex justify-center items-center text-[16px] text-red-600'>
										لاتوجد بيانات
									</div>
								)}
							</div>

							{/*
							<div className='flex flex-col gap-[10px]'>
								<label
									style={{ color: "#011723" }}
									className='md:text-[20px] text-[16px]'
									htmlFor='owner'>
									المالك
								</label>
								<input
									id='owner'
									className='md:text-[20px] text-[16px] py-[14px] px-[24px] rounded-md outline-none'
									style={{ backgroundColor: "#FAFAFA", color: "#011723" }}
									type='text'
									placeholder='خالد محمد'
									value={data?.store_owner}
									onChange={(e) =>
										setData({ ...data, store_owner: e.target.value })
									}
									readOnly
								/>
							</div>
						*/}

							<div className='flex flex-col gap-[10px]'>
								<label
									style={{ color: "#011723" }}
									className='md:text-[20px] text-[16px]'
									htmlFor='store_name'>
									اسم المتجر
								</label>
								<input
									id='store_name'
									className='md:text-[20px] text-[16px] py-[14px] px-[24px] rounded-md outline-none'
									style={{ backgroundColor: "#FAFAFA", color: "#011723" }}
									type='text'
									placeholder='أمازون'
									value={data.store_name}
									onChange={(e) =>
										setData({ ...data, store_name: e.target.value })
									}
									readOnly
								/>
							</div>

							<div className='flex flex-col gap-[10px]'>
								<label
									style={{ color: "#011723" }}
									className='md:text-[20px] text-[16px]'
									htmlFor='store_email'>
									البريد الالكتروني
								</label>
								<input
									id='store_name'
									className='md:text-[20px] text-[16px] py-[14px] px-[24px] rounded-md outline-none'
									style={{ backgroundColor: "#FAFAFA", color: "#011723" }}
									type='email'
									placeholder='name@name.com'
									value={data.email}
									onChange={(e) => setData({ ...data, email: e.target.value })}
									readOnly
								/>
							</div>

							<div className='flex flex-col gap-[10px]'>
								<label
									style={{ color: "#011723" }}
									className='md:text-[20px] text-[16px]'
									htmlFor='email'>
									رقم الجوال
								</label>

								<div className='w-full flex flex-row items-center bg-[#FAFAFA] rounded-md pr-3'>
									<PhoneIcon className={styles.icon} />
									<div
										className='w-full flex flex-row items-center gap-[1px] md:text-[20px] text-[16px] py-[14px] pl-3  bg-transparent '
										style={{
											color: "#1948a0",
											direction: "ltr",
										}}>
										<span>+966</span>
										<input
											type='tel'
											name='phonenumber'
											value={data.phonenumber}
											onChange={(e) =>
												setData({ ...data, phonenumber: e.target.value })
											}
											readOnly
											placeholder={500000000}
											className='w-full h-full bg-transparent text-[#011723]  outline-none '
											style={{
												direction: "ltr",
											}}
										/>
									</div>
								</div>
							</div>
							<div className='flex flex-col gap-[10px]'>
								<label
									style={{ color: "#011723" }}
									className='md:text-[20px] text-[16px]'
									htmlFor='domain'>
									الدومين{" "}
									<span className='text-sm text-[#1948a0]'>
										{data?.domain_type === "later_time"
											? "(في وقت لاحق)"
											: data?.domain_type === "has_domain"
											? "(لدي دومين)"
											: data?.domain_type === "pay_domain"
											? "(أريد شراء دومين)"
											: null}
									</span>
								</label>
								{data?.domain ? (
									<input
										id='owner_name'
										className='md:text-[20px] text-[16px] py-[14px] px-[24px] rounded-md outline-none'
										style={{ backgroundColor: "#FAFAFA", color: "#011723" }}
										type='text'
										placeholder='الدومين'
										value={data?.domain}
										onChange={(e) =>
											setData({ ...data, domain: e.target.value })
										}
									/>
								) : (
									<div
										className='md:text-[20px] text-[16px] py-[14px] px-[24px] text-red-600 rounded-md outline-none'
										style={{ backgroundColor: "#FAFAFA" }}>
										لا توجد بيانات
									</div>
								)}
							</div>

							<div className='flex flex-col gap-[10px]'>
								<label
									style={{ color: "#011723" }}
									className='md:text-[20px] text-[16px]'
									htmlFor='owner_name'>
									{data?.verification_type === "maeruf"
										? "اسم مالك الوثيقة"
										: "اسم السجل التجاري"}
								</label>
								<input
									id='owner_name'
									className='md:text-[20px] text-[16px] py-[14px] px-[24px] rounded-md outline-none'
									style={{ backgroundColor: "#FAFAFA", color: "#011723" }}
									type='text'
									placeholder='اسم المتجر'
									value={data?.owner_name}
									onChange={(e) =>
										setData({ ...data, owner_name: e.target.value })
									}
									readOnly
								/>
							</div>

							<div className='flex flex-col gap-[10px]'>
								<label
									style={{ color: "#011723" }}
									className='md:text-[20px] text-[16px]'
									htmlFor='verification_code'>
									{data?.verification_type === "maeruf"
										? "رقم الوثيقة"
										: "رقم السجل التجاري"}
								</label>
								<input
									id='verification_code'
									className='md:text-[20px] text-[16px] py-[14px] px-[24px] rounded-md outline-none'
									style={{ backgroundColor: "#FAFAFA", color: "#011723" }}
									type='text'
									placeholder='001203310'
									value={data?.verification_code}
									onChange={(e) =>
										setData({ ...data, verification_code: e.target.value })
									}
									readOnly
								/>
							</div>
							<div className='flex flex-col gap-[10px]'>
								<label
									style={{ color: "#011723" }}
									className='md:text-[20px] text-[16px]'>
									{data?.verification_type === "maeruf"
										? "وثيقة العمل الحر "
										: "السجل التجاري"}
								</label>
								<>
									<div className='relative upload__image-wrapper'>
										<div className='w-full image-item '>
											<div
												className='flex items-center justify-between p-4 mt-2 cursor-pointer'
												style={{
													backgroundColor: "#FAFAFA",
												}}>
												<div className='flex flex-row items-center justify-between gap-2 md:justify-start'>
													<h2
														className='md:text-[20px] text-[16px] whitespace-nowrap'
														style={{ color: "#011723" }}>
														{data?.verification_type === "maeruf"
															? "وثيقة العمل الحر "
															: "السجل التجاري"}
													</h2>
													<PDF />
												</div>
												<div
													className='flex-1 overflow-hidden text-left'
													style={{ direction: "ltr" }}>
													<span
														className='md:text-[16px] text-[14px] md:flex '
														style={{
															color: "#0099FB",
															overflow: "hidden",
															textOverflow: "ellipsis",
															whiteSpace: "nowrap",
														}}>
														<a
															style={{ cursor: "pointer" }}
															href={data?.file}
															download={data?.file}
															target='_blank'
															rel='noreferrer'>
															<span>
																{data?.verification_type === "maeruf"
																	? "تحميل وثيقة العمل الحر"
																	: "تحميل السجل التجاري"}
															</span>
														</a>
													</span>
												</div>
											</div>
										</div>
									</div>
								</>
							</div>
						</>
					)}
				</div>
				<div className='flex flex-row items-center'>
					<Button
						disabled={
							!hasAcceptVerificationPermission ||
							loading ||
							fetchedData?.store?.verification_status === "تم التوثيق" ||
							btnLoading
						}
						onClick={acceptVerification}
						type={"normal"}
						className='md:h-[60px] h-[45px] save_button md:text-[18px] text-[16px]  text-[#1948a0]text-center w-full py-4 rounded-none font-medium'>
						قبول التوثيق
					</Button>
					<Button
						disabled={!hasRejectVerificationPermission || loading || btnLoading}
						onClick={rejectVerification}
						type={"outline"}
						style={{ borderColor: "#f75e22" }}
						textStyle={{ color: "#f75e22" }}
						className='md:h-[60px] h-[45px] bg-white md:text-[18px] text-[16px]  text-[#1948a0]text-center w-full py-4 rounded-none font-medium'>
						رفض التوثيق
					</Button>
				</div>
			</div>
		</>
	);
};

export default ShowVerificationData;
