import React, { useState } from "react";
/* Third party */
import { Helmet } from "react-helmet";
/* Custom Hook */

/* Components */
import PageNavigate from "../../../components/PageNavigate/PageNavigate";
import ProductsTableSec from "../../../components/MarketsPagesComp/ProductsTable/ProductsTableSec/ProductsTableSec";
import ProductDetails from "../../../components/MarketsPagesComp/ProductsTable/ProductDetails/ProductDetails";
import TraderAlert from "../../../components/MarketsPagesComp/ProductsTable/TraderAlert/TraderAlert";
import { useGetProductsQuery } from "../../../RTK/apiSlices/productsApi";

const ProductsTable = () => {
	const [openProductDetails, setOpenProductDetails] = useState(false);
	const [details, setDetails] = useState([]);
	const [traderAlert, setTraderAlert] = useState(false);
	const [traderPackageDetails, setTraderPackageDetails] = useState({});
	const [search, setSearch] = useState("");
	const [searchResults, setSearchResults] = useState([]);

	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const { data: fetchedData, isLoading: loading } = useGetProductsQuery({
		page,
		number: rowsPerPage,
	});

	let products = fetchedData?.products || [];

	if (search !== "") {
		products = searchResults?.products;
	} else {
		products = fetchedData?.products;
	}

	return (
		<>
			<Helmet>
				<title>لوحة تحكم سوق نيوم | المنتجات</title>
			</Helmet>
			<div className='p-4 md:bg-[#fafafa] bg-[#ffffff]'>
				<div className='flex items-center justify-between'>
					<PageNavigate
						route={"markets_section"}
						currentPage={"المنتجات"}
						parentPage={"المتاجر"}
					/>
				</div>
				{openProductDetails && (
					<ProductDetails
						cancel={() => {
							setOpenProductDetails(false);
						}}
						details={details}
					/>
				)}
				{traderAlert && (
					<TraderAlert
						cancel={() => {
							setTraderAlert(false);
						}}
						traderPackageDetails={traderPackageDetails}
					/>
				)}
				<div className='mt-4 md:mt-8' dir='ltr'>
					<ProductsTableSec
						fetchedData={products}
						loading={loading}
						openProductDetails={(id) => {
							setOpenProductDetails(true);
							setDetails(id);
						}}
						openTraderAlert={(row) => {
							setTraderAlert(true);
							setTraderPackageDetails(row);
						}}
						search={search}
						setSearch={setSearch}
						setSearchResults={setSearchResults}
						pageNumber={page}
						setPageNumber={setPage}
						currentPage={rowsPerPage}
						setCurrentPage={setRowsPerPage}
						page_count={fetchedData?.page_count}
					/>
				</div>
			</div>
		</>
	);
};

export default ProductsTable;
