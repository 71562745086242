import React from "react";
/* Components */
import PieChartSec from "./PieChartSec/PieChartSec";
/* MUI */
import Box from "@mui/material/Box";
/* Icons */
import { Store } from "../../../assets/Icons";

const ChartsComp = ({
	storeTechnicalSupports,
	percentStoreTechnicalSupports,
	pending,
	finished,
	technicalCount,
}) => {
	return (
		<div className='flex lg:flex-row flex-col gap-4 lg:h-[190px] h-full'>
			<div
				className='lg:w-[376px] w-full h-full rounded-lg md:py-10 px-5 py-5 flex flex-col md:items-start items-center justify-between'
				style={{
					backgroundColor: "#feb17e7b",
					boxShadow: "0px 3px 6px #0000000F",
				}}>
				<div className='flex items-center gap-4 mb-3'>
					<Box sx={{ "& path": { fill: "#f75e22" } }}>
						<Store width={"1.75rem"} height={"1.75rem"}></Store>
					</Box>
					<h2 className='text-xl font-medium' style={{ color: "#1948a0" }}>
						متاجر قدمت شكوى
					</h2>
				</div>
				<div className='flex flex-row items-baseline gap-4'>
					<h2 className='text-3xl font-semibold' style={{ color: "#1948a0" }}>
						{storeTechnicalSupports || 0}
					</h2>
					<span className='text-[14px] font-light' style={{ color: "#f75e22" }}>
						{percentStoreTechnicalSupports || 0}
					</span>
				</div>
			</div>
			<div
				className='flex items-center flex-1 px-5 py-5 rounded-lg md:py-10'
				style={{
					backgroundColor: "#feb17e7b",
					boxShadow: "0px 3px 6px #0000000F",
				}}>
				<PieChartSec
					technicalCount={technicalCount}
					pending={pending}
					finished={finished}
				/>
			</div>
		</div>
	);
};

export default ChartsComp;
