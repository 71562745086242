import React, { useState, useEffect, useContext } from "react";
/* Third party */
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
/* Custom Hook */

/* Contexts */
import { LoadingContext } from "../../../store/LoadingProvider";
/* Components */
import CircularLoading from "../../../UI/CircularLoading/CircularLoading";
/* Custom Button */
import Button from "../../../UI/Button/Button";
/* MUI */
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FormGroup from "@mui/material/FormGroup";
/* Icons */
import { GoArrowRight } from "react-icons/go";
import { AiFillStar } from "react-icons/ai";
import { AiOutlineSearch } from "react-icons/ai";
import { Paper } from "@mui/material";
import {
	useGetPermissionsQuery,
	useGetRoleDataQuery,
	useUpdateRoleItemMutation,
} from "../../../RTK/apiSlices/rolesApi";

const EditRole = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const { data: permissionsListData } = useGetPermissionsQuery();
	const { data: fetchedData, isLoading: loading } = useGetRoleDataQuery({
		roleId: id,
	});
	const [search, setSearch] = useState("");
	const [roleName, setRoleName] = useState("");
	const [permissions, setPermissions] = useState([]);
	const LoadingStore = useContext(LoadingContext);
	const { setLoadingTitle } = LoadingStore;
	let permissionsList = permissionsListData?.permissions;
	const [DataError, setDataError] = useState({
		role_name: "",
		permissions: "",
	});
	const resetData = () => {
		setDataError({
			role_name: "",
			permissions: "",
		});
	};
	if (search !== "") {
		permissionsList = permissionsListData?.permissions?.filter((item) =>
			item?.name_ar?.toLowerCase()?.includes(search?.toLowerCase())
		);
	} else {
		permissionsList = permissionsListData?.permissions;
	}

	useEffect(() => {
		if (fetchedData?.status === 200) {
			setRoleName(fetchedData?.role?.name);
			setPermissions(
				fetchedData?.role?.permissions?.map((per) => per?.id) || []
			);
		}
	}, [fetchedData]);

	const [updateRoleItem] = useUpdateRoleItemMutation();

	const updateRoleItemHandel = async (formData) => {
		try {
			const res = await updateRoleItem({
				roleId: id,
				body: formData,
			});
			if (res?.data?.success === true && res?.data?.data?.status === 200) {
				setLoadingTitle(null);

				navigate("/users/roles");
			} else {
				setLoadingTitle(null);
				setDataError({
					role_name: res?.data?.message?.en?.role_name?.[0],
					permissions: res?.data?.message?.en?.permissions?.[0],
				});
				toast.error(res?.data?.message?.en?.role_name?.[0], {
					theme: "light",
				});
				toast.error(res?.data?.message?.en?.permissions?.[0], {
					theme: "light",
				});
			}
		} catch (error) {
			console.error(error);
		}
	};

	const updateRole = () => {
		resetData();
		setLoadingTitle("جاري تعديل الدور");
		let formData = new FormData();
		formData.append("_method", "PUT");
		formData.append("role_name", roleName);
		for (var i = 0; i < permissions.length; i++) {
			formData.append("permissions[]", permissions[i]);
		}
		updateRoleItemHandel(formData);
	};

	return (
		<>
			<Helmet>
				<title>لوحة تحكم سوق نيوم | تعديل دور</title>
			</Helmet>
			<div
				className={`fixed top-20 left-0 md:pt-5 bg-slate-50 z-50  full_screen_page flex  flex-col justify-between`}
				style={{
					width: "100%",
					maxWidth: "100%",
					height: "calc(100vh - 5rem)",
				}}>
				<div
					className='flex flex-col flex-1 h-4 px-4 py-8 xl:pl-16 xl:pr-16 xl:pt-5 xl:pb-4'
					style={{ backgroundColor: "#F7F7F7" }}>
					<div
						className='md:h-[120px] flex md:flex-row flex-col items-center justify-between gap-y-4 rounded-lg md:pr-12 md:pl-5 pt-2 pb-4 px-3'
						style={{ maxWidth: "100%" }}>
						<h2 className='text-3xl font-normal text-center text-slate-50'>
							{loading ? "جاري التحميل ... " : fetchedData?.role?.name}
						</h2>
						<div className='flex flex-col gap-4 md:flex-row'>
							<label
								className={`flex-1 md:h-14 h-[45px] md:w-[356px] w-full relative `}>
								<input
									value={search}
									onChange={(e) => setSearch(e.target.value)}
									className='outline-0 md:w-[356px] w-[254px] md:h-14 h-[45px] pr-9 rounded-lg text-lg font-normal text-[#000000] '
									placeholder=' ابحث عن صلاحية'
									type='text'
									name='name'
								/>
								<div className={`absolute top-1/2 right-4 -translate-y-1/2`}>
									<AiOutlineSearch
										color='#1948A0'
										size={"18px"}></AiOutlineSearch>
								</div>
							</label>
							<Button
								className='text-2xl save_button font-normal md:h-14 h-[45px] md:w-[181px] w-[254px]'
								type={"normal"}
								fontWeight={"font-light"}
								onClick={() => {
									updateRole();
								}}>
								حفظ واعتماد
							</Button>
						</div>
					</div>
					<div className='flex-1 overflow-y-scroll mt-7 '>
						<div className='flex mb-3'>
							<div className={`flex items-center gap-2 `}>
								<div
									onClick={() => {
										navigate("/users/roles");
									}}
									className={`flex items-center gap-2 bg-[#1948a017] justify-center h-6 w-6 rounded-full cursor-pointer`}>
									<GoArrowRight
										style={{ color: "#1948a0", fontSize: "1.2rem" }}
									/>
								</div>
								<h2 className='font-medium md:text-lg text-[16px] ml-4'>
									{" "}
									الأدوار الوظيفية{" "}
								</h2>
							</div>

							<h3 className='font-medium md:text-lg text-[16px] text-[#7C7C7C]'>
								{" "}
								/ تعديل الصلاحيات
							</h3>
						</div>
						<div
							className={`flex-1 px-4`}
							style={{ backgroundColor: "#F6F6F6" }}>
							<div className='mb-4 md:my-6'>
								<h2
									className='font-normal md:text-[18px] text-[16px]  text-[#1948a0]mb-2'
									style={{ color: "#011723" }}>
									<AiFillStar
										style={{
											display: "inline-block",
											marginLeft: "0.5rem",
											color: "red",
										}}></AiFillStar>
									اسم الدور الوظيفي<span className='text-red-500'>*</span>
								</h2>
								<input
									className='md:w-[376px] w-full outline-none p-4 rounded-lg h-14 '
									style={{
										border: "1px solid #86B1EA",
										backgroundColor: "#EEF4FC",

										color: "#1948a0",
									}}
									type='text'
									placeholder='ادخل اسم الدور بالحروف فقط'
									value={roleName}
									onChange={(e) => setRoleName(e.target.value)}
								/>
								<br />
								<span className='text-red-500 text-[16px]'>
									{DataError?.role_name}
								</span>
							</div>
							<div className='mb-5 md:mt-5'>
								<h2
									className='font-normal md:text-[18px] text-[16px]  text-[#1948a0]whitespace-nowrap mb-2'
									style={{ color: "#011723" }}>
									<AiFillStar
										style={{
											display: "inline-block",
											marginLeft: "0.5rem",
											color: "red",
										}}></AiFillStar>
									حدد الصلاحيات<span className='text-red-500'>*</span>
								</h2>
								<br />
								<span className='text-red-500 text-[16px]'>
									{DataError?.permissions}
								</span>
							</div>
							<div className='bg-[#FAFAFA] h-full'>
								{loading ? (
									<div className='flex flex-col items-center justify-center w-full h-full'>
										<CircularLoading />
									</div>
								) : (
									permissionsList?.map((row, index) => (
										<TableContainer
											key={index}
											component={Paper}
											sx={{ p: 0, w: 100, marginBottom: "24px" }}>
											<Table sx={{ direction: "rtl !important" }}>
												<TableHead
													sx={{
														bgColor: "#eff9ff",
														fontSize: "1.2rem",
													}}>
													<TableRow>
														<TableCell
															sx={{
																fontSize: "1.2rem",
																backgroundColor: "#eff9ff",
															}}
															align='right'>
															اسم الصلاحية
														</TableCell>
														{row?.subpermissions?.map((sub, index) => (
															<TableCell
																key={index}
																TableCell
																align='center'
																sx={{
																	fontSize: "1rem",
																	textAlign: "right",
																	backgroundColor: "#eff9ff",
																}}>
																{sub?.name_ar}
															</TableCell>
														))}
													</TableRow>
												</TableHead>
												<TableBody>
													{loading ? (
														<TableRow>
															<TableCell colSpan={6}>
																<CircularLoading />
															</TableCell>
														</TableRow>
													) : (
														<TableRow key={index}>
															<TableCell
																sx={{
																	border: "none",

																	fontSize: "1.2rem",
																	padding: "10px",
																}}
																align='right'>
																{row?.name_ar}
															</TableCell>
															{row?.subpermissions?.map((sub, index) => (
																<TableCell
																	key={index}
																	sx={{ border: "none" }}
																	align='center'>
																	<FormGroup
																		className=''
																		sx={{ overflow: "hidden" }}>
																		<FormControlLabel
																			value={sub?.id}
																			key={index}
																			sx={{
																				py: 1,
																				mr: 0,

																				"& .MuiTypography-root": {
																					fontSize: "20px",
																					fontWeight: "500",
																					"@media(max-width:767px)": {
																						fontSize: "16px",
																					},
																				},
																			}}
																			control={
																				<Checkbox
																					checked={
																						permissions?.includes(sub?.id) ||
																						false
																					}
																					onChange={(e) => {
																						if (e.target.checked) {
																							setPermissions([
																								...permissions,
																								parseInt(e.target.value),
																							]);
																						} else {
																							setPermissions(
																								permissions?.filter(
																									(item) => item !== sub?.id
																								)
																							);
																						}
																					}}
																					sx={{ "& path": { fill: "#000000" } }}
																				/>
																			}
																		/>
																	</FormGroup>
																</TableCell>
															))}
														</TableRow>
													)}
												</TableBody>
											</Table>
										</TableContainer>
									))
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default EditRole;
