import React, { useState, useEffect, useContext, Fragment } from "react";
import styles from "./ProductsTableSec.module.css";
import PropTypes from "prop-types";
/* Third party */
import moment from "moment";
/* Context */
import Context from "../../../../store/context";
import { NotificationContext } from "../../../../store/NotificationProvider";
import CircularLoading from "../../../../UI/CircularLoading/CircularLoading";
import { DeleteContext } from "../../../../store/DeleteProvider";
import { RolePermissionsContext } from "../../../../store/RolePermissionsProvider";
/* Components */
import DynamicMenu from "../../../DynamicMenu/DynamicMenu";
/* MUI */
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";
import { visuallyHidden } from "@mui/utils";

import PlaceholderImage from "../../../../assets/images/placeholderImage.png";

/* Icons */
import {
	CheckedSquare,
	Delete,
	LinkArrow,
	SendNote,
	SortIcon,
} from "../../../../assets/Icons/index";
import { AiOutlineSearch } from "react-icons/ai";
import {
	useProductChangeSpecialByParamsMutation,
	useProductChangeSpecialMutation,
	useProductChangeStatusAllByParamsMutation,
	useProductChangeStatusAllMutation,
	useProductDeleteAllMutation,
	useProductDeleteItemMutation,
	useSearchInProductsMutation,
} from "../../../../RTK/apiSlices/productsApi";
import NewPagination from "../../../PaginationComp/NewPagination";
import DeleteOneModalComp from "../../../DeleteOneModal/DeleteOneModal";
import DeleteModalComp from "../../../DeleteModal/DeleteModal";
import { toast } from "react-toastify";
// ---------------------------------------------------

// switch styles
const switchStyle = {
	width: "26px",
	padding: "12px 0",

	"& .MuiSwitch-thumb": {
		width: "10px",
		height: "10px",
	},
	"& .MuiSwitch-switchBase": {
		padding: "6px",
		top: "9px",
		left: "-4px",
	},
	"& .MuiSwitch-switchBase.Mui-checked": {
		left: "-12px",
	},
	"& .Mui-checked .MuiSwitch-thumb": {
		backgroundColor: "#EEF4FC",
	},
	"& .MuiSwitch-track": {
		height: "16px",
		borderRadius: "20px",
	},
	"&.MuiSwitch-root .Mui-checked+.MuiSwitch-track": {
		backgroundColor: "#3AE374",
		opacity: 1,
	},
};

const changeAllSwitch = {
	width: "50px",

	"& .MuiSwitch-thumb": {
		width: "11px",
		height: "11px",
	},
	"& .MuiSwitch-switchBase": {
		padding: "6px",
		top: "9px",
		left: "9px",
	},
	"& .MuiSwitch-switchBase.Mui-checked": {
		left: "-1px",
	},
	"& .Mui-checked .MuiSwitch-thumb": {
		backgroundColor: "#EEF4FC",
	},
	"& .MuiSwitch-track": {
		height: "16px",
		borderRadius: "20px",
	},
	"&.MuiSwitch-root .Mui-checked+.MuiSwitch-track": {
		backgroundColor: "#3AE374",

		opacity: 1,
	},
};
// ---------------------------------------------------

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array?.map((el, index) => [el, index]);
	stabilizedThis?.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis?.map((el) => el[0]);
}

// Table Header
const headCells = [
	{
		id: "situation",
		numeric: false,
		disablePadding: false,
		label: "الإجراء",
		width: "5rem",
	},
	{
		id: "date",
		numeric: true,
		disablePadding: false,
		label: (
			<div className='flex flex-col items-center'>
				<h2 style={{ color: "#1948a0", fontSize: "14px", fontWeight: "500" }}>
					التاريخ
				</h2>
				<span style={{ color: "#4875c2", fontSize: "14px", fontWeight: "500" }}>
					الاضافة/ التعديل
				</span>
			</div>
		),
		sort: true,
		sortLabel: "created_at",
	},
	{
		id: "special",
		numeric: true,
		disablePadding: false,
		label: "مميز",
		sort: true,
		sortLabel: "admin_special",
		textAlign: "center",
	},
	{
		id: "activity",
		numeric: true,
		disablePadding: false,
		label: "الأنشطة",
		width: "5rem",
	},
	{
		id: "status",
		numeric: true,
		disablePadding: false,
		label: "الحالة",
		sort: true,
		sortLabel: "status",
		textAlign: "center",
	},
	{
		id: "store",
		numeric: true,
		disablePadding: false,
		label: "المتجر",
	},
	{
		id: "product",
		numeric: true,
		disablePadding: false,
		label: "اسم المنتج",
	},

	{
		id: "number",
		numeric: true,
		textAlign: "left",
		disablePadding: false,
		label: "م",
	},
];

function EnhancedTableHead(props) {
	const { order, orderBy, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead sx={{ backgroundColor: "#feb17e" }}>
			<TableRow>
				{headCells?.map((headCell) => (
					<TableCell
						className='md:text-[18px] text-[14px] font-medium'
						key={headCell.id}
						align={headCell.numeric ? "right" : "center"}
						padding={headCell.disablePadding ? "none" : "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
						sx={{
							width: headCell.width ? headCell.width : "auto",
							color: "#FFF",
							whiteSpace: "nowrap",
							textAlign: headCell.textAlign,
						}}>
						{headCell.sort && (
							<TableSortLabel
								IconComponent={() => {
									return <SortIcon />;
								}}
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : "asc"}
								onClick={createSortHandler(headCell.sortLabel)}>
								{headCell.label}
								{!orderBy === headCell.id ? (
									<Box component='span' sx={visuallyHidden}>
										{order === "desc"
											? "sorted descending"
											: "sorted ascending"}
									</Box>
								) : null}
							</TableSortLabel>
						)}
						{!headCell.sort && headCell.label}
					</TableCell>
				))}
				<TableCell padding={"none"}></TableCell>
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number,
};

function EnhancedTableToolbar(props) {
	const {
		numSelected,
		rowCount,
		onSelectAllClick,
		search,
		setSearch,

		itemsSelected,
	} = props;
	const NotificationStore = useContext(NotificationContext);
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;
	const [all, setAll] = useState(true);
	const { setNotificationTitle, setActionType, setItems } = NotificationStore;

	return (
		<Toolbar
			className='flex flex-row flex-wrap items-center justify-end gap-2 p-0 px-2 mb-4 md:mb-0'
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) =>
						alpha(
							theme.palette.primary.contrastText,
							theme.palette.action.activatedOpacity
						),
				}),
			}}>
			<div className={styles.search}>
				<input
					value={search}
					onChange={(e) => setSearch(e.target.value)}
					placeholder='يمكنك البحث عن طريق اسم المنتج أو المتجر'
				/>
				<AiOutlineSearch color='#898989' size={"18px"}></AiOutlineSearch>
			</div>
			<div className='flex flex-col-reverse flex-wrap items-end justify-end flex-1 gap-4 p-0 mb-2 sm:flex-row md:flex-nowrap'>
				<div className='flex flex-row items-center justify-center gap-2'>
					{numSelected > 0 && (
						<Fragment>
							{permissions?.includes("admin.product.changeSatusall") && (
								<div
									className='md:w-[126px] w-[100px] md:h-[40px] h-[30px] flex flex-row items-center justify-center md:gap-3 gap-1 cursor-pointer'
									style={{ backgroundColor: "#FF9F1A0A", borderRadius: "20px" }}
									onClick={() => {
										setNotificationTitle(
											"سيتم تغيير حالة جميع المنتجات التي قمت بتحديدها"
										);
										setItems(itemsSelected);
										setActionType("ChangeSpecial");
									}}>
									<h6
										style={{ color: "#FF9F1A" }}
										className='font-medium md:text-[18px] text-[15px]'>
										مميز
									</h6>
									<Switch
										onChange={() => {}}
										className=''
										sx={{
											width: "50px",
											"& .MuiSwitch-thumb": {
												width: "11px",
												height: "11px",
											},
											"& .MuiSwitch-switchBase": {
												padding: "6px",
												top: "9px",
												left: "9px",
											},
											"& .MuiSwitch-switchBase.Mui-checked": {
												left: "-1px",
											},
											"& .Mui-checked .MuiSwitch-thumb": {
												backgroundColor: "#EEF4FC",
											},
											"& .MuiSwitch-track": {
												height: "16px",
												borderRadius: "20px",
											},
											"&.MuiSwitch-root .Mui-checked+.MuiSwitch-track": {
												backgroundColor: "#FF9F1A",

												opacity: 1,
											},
										}}
										checked={all}
									/>
								</div>
							)}
							{permissions?.includes("admin.product.changeSatusall") && (
								<div
									className='md:w-[126px] w-[100px] md:h-[40px] h-[30px] flex flex-row items-center justify-center md:gap-3 gap-1 cursor-pointer'
									style={{ backgroundColor: "#FF9F1A0A", borderRadius: "20px" }}
									onClick={() => {
										setNotificationTitle(
											"سيتم تغيير حالة جميع المنتجات التي قمت بتحديدها"
										);
										setItems(itemsSelected);
										setActionType("ChangeStatus");
										// setActionTitle("ChangeStatus");
									}}>
									<h6
										style={{ color: "#FF9F1A" }}
										className='font-medium md:text-[18px] text-[15px]'>
										تعطيل
									</h6>
									<Switch
										onChange={() => {}}
										className=''
										sx={{
											width: "50px",
											"& .MuiSwitch-thumb": {
												width: "11px",
												height: "11px",
											},
											"& .MuiSwitch-switchBase": {
												padding: "6px",
												top: "9px",
												left: "9px",
											},
											"& .MuiSwitch-switchBase.Mui-checked": {
												left: "-1px",
											},
											"& .Mui-checked .MuiSwitch-thumb": {
												backgroundColor: "#EEF4FC",
											},
											"& .MuiSwitch-track": {
												height: "16px",
												borderRadius: "20px",
											},
											"&.MuiSwitch-root .Mui-checked+.MuiSwitch-track": {
												backgroundColor: "#FF9F1A",
												opacity: 1,
											},
										}}
										checked={all}
									/>
								</div>
							)}
							{permissions?.includes("admin.product.deleteall") && (
								<div
									className='md:w-[114px] w-[90px] md:h-[40px] h-[30px] flex flex-row items-center justify-center md:gap-4 gap-2 cursor-pointer'
									style={{ backgroundColor: "#FF38381A", borderRadius: "20px" }}
									onClick={() => {
										setNotificationTitle(
											"سيتم حذف جميع المنتجات التي قمت بتحديدها"
										);
										setItems(itemsSelected);
										setActionType("deleteAll");
									}}>
									<h6
										style={{ color: "#FF3838" }}
										className='md:text-[18px] text-[15px] font-medium'>
										حذف الكل
									</h6>
									<Delete />
								</div>
							)}
						</Fragment>
					)}
				</div>
				<div className='flex items-center'>
					<h2 className='font-medium md:text-[18px] text-[16px]  text-[#1948a0] text-[#1948a0] whitespace-nowrap'>
						تحديد الكل
					</h2>
					<Checkbox
						checkedIcon={<CheckedSquare />}
						sx={{
							pr: "0",
							color: "#1948a0",
							"& .MuiSvgIcon-root": {
								color: "#1948a0",
							},
						}}
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{
							"aria-label": "select all desserts",
						}}
					/>
				</div>
			</div>
		</Toolbar>
	);
}

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({
	fetchedData,
	loading,
	openProductDetails,
	openTraderAlert,
	search,
	setSearch,
	setSearchResults,
	pageNumber,
	setPageNumber,
	currentPage,
	setCurrentPage,
	page_count,
}) {
	const [order, setOrder] = React.useState("asc");
	const [orderBy, setOrderBy] = React.useState("calories");
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const NotificationStore = useContext(NotificationContext);
	const { notificationTitle } = NotificationStore;
	const DeleteStore = useContext(DeleteContext);
	const { setItemId, setActionDelete, actionDelete } = DeleteStore;
	const RolePermissionsStore = useContext(RolePermissionsContext);
	const { permissions } = RolePermissionsStore;

	const contextStore = useContext(Context);
	const { setEndActionTitle } = contextStore;

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelected = fetchedData?.map((n) => n.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const [productChangeStatusAll] = useProductChangeStatusAllMutation();
	const [productChangeSpecial] = useProductChangeSpecialMutation();
	const [productChangeStatusAllByParams] =
		useProductChangeStatusAllByParamsMutation();
	const [productChangeSpecialByParams] =
		useProductChangeSpecialByParamsMutation();
	const [productDeleteAll] = useProductDeleteAllMutation();
	const [productDeleteItem] = useProductDeleteItemMutation();

	const changeProductStatus = async (id) => {
		try {
			await productChangeStatusAll({ id })
				.unwrap()
				.then((data) => {
					if (data.success) {
						setEndActionTitle(data.message.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the category", err);
		}
	};
	const changeProductSpecialStatus = async (id) => {
		try {
			await productChangeSpecial({ id })
				.unwrap()
				.then((data) => {
					if (data.success) {
						setEndActionTitle(data.message.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the category", err);
		}
	};
	const productChangeStatusAllByParamsHandel = async (selected) => {
		const queryParams = selected?.map((id) => `id[]=${id}`).join("&");
		try {
			await productChangeStatusAllByParams({ queryParams })
				.unwrap()
				.then((data) => {
					if (data.success) {
						setEndActionTitle(data.message.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the category", err);
		}
	};
	const productChangeSpecialByParamsHandel = async (selected) => {
		const queryParams = selected?.map((id) => `id[]=${id}`).join("&");
		try {
			await productChangeSpecialByParams({ queryParams })
				.unwrap()
				.then((data) => {
					if (data.success) {
						setEndActionTitle(data.message.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the category", err);
		}
	};
	const productDeleteAllHandel = async (selected) => {
		const queryParams = selected?.map((id) => `id[]=${id}`).join("&");
		try {
			await productDeleteAll({ queryParams })
				.unwrap()
				.then((data) => {
					if (data.success) {
						setEndActionTitle(data.message.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the category", err);
		}
	};
	// delete item
	const handleDeleteSingleItem = async (id) => {
		try {
			await productDeleteItem({ id })
				.unwrap()
				.then((data) => {
					if (!data?.success) {
						toast.error(data?.message?.ar, {
							theme: "light",
						});
					} else {
						setEndActionTitle(data?.message?.ar);
					}
				});
		} catch (err) {
			console.error("Failed to delete the etlbha Product", err);
		}
	};

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	const isSelected = (name) => selected.indexOf(name) !== -1;

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - fetchedData?.length) : 0;

	const [searchInProducts, { isLoading: searchIsLoading }] =
		useSearchInProductsMutation();

	useEffect(() => {
		const debounce = setTimeout(() => {
			if (search) {
				const fetchData = async () => {
					try {
						const response = await searchInProducts({
							query: search,
						});

						setSearchResults(response.data.data);
					} catch (error) {
						console.error("Error fetching orders:", error);
					}
				};

				fetchData();
			} else {
				setSearchResults(fetchedData);
			}
		}, 500);
		return () => {
			clearTimeout(debounce);
		};
	}, [search]);

	// handle previewProductOnStore function
	const previewProductOnStore = (productUrl) => {
		window.open(productUrl, "_blank");
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Paper
				sx={{
					backgroundColor: "transparent",
					width: "100%",
					mb: 2,
					boxShadow: "0 0",
				}}>
				<EnhancedTableToolbar
					itemsSelected={selected}
					numSelected={selected.length || 0}
					rowCount={fetchedData?.length || 0}
					onSelectAllClick={handleSelectAllClick}
					search={search}
					setSearch={setSearch}
					setSearchResults={setSearchResults}
				/>
				<TableContainer>
					<Table
						sx={{
							minWidth: 750,
							backgroundColor: "#ffffff",
							marginBottom: "3rem",
						}}
						aria-labelledby='tableTitle'
						size={"medium"}>
						<EnhancedTableHead
							numSelected={selected.length || 0}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={fetchedData?.length || 0}
						/>
						<TableBody>
							{loading || searchIsLoading ? (
								<TableRow>
									<TableCell colSpan={9}>
										<CircularLoading />
									</TableCell>
								</TableRow>
							) : (
								<Fragment>
									{fetchedData?.length === 0 ? (
										<TableRow>
											<TableCell colSpan={9}>
												<p className='text-center'>لاتوجد بيانات</p>
											</TableCell>
										</TableRow>
									) : (
										stableSort(fetchedData, getComparator(order, orderBy))?.map(
											(row, index) => {
												const isItemSelected = isSelected(row.id);
												const labelId = `enhanced-table-checkbox-${index}`;
												return (
													<TableRow
														hover
														role='checkbox'
														aria-checked={isItemSelected}
														tabIndex={-1}
														key={row.id}
														selected={isItemSelected}>
														<TableCell component='th' id={labelId} scope='row'>
															<div className='flex items-center gap-2'>
																{permissions?.includes(
																	"admin.product.deleteall"
																) && (
																	<Delete
																		title='حذف'
																		onClick={() => {
																			setActionDelete(
																				"سيتم حذف المنتج وهذة الخطوة غير قابلة للرجوع"
																			);
																			setItemId(row?.id);
																			// setDeleteMethod("get");
																			// setUrl(
																			//   `/productdeleteall?id[]=${row?.id}`
																			// );
																		}}
																		style={{
																			cursor: "pointer",
																			color: "red",
																			fontSize: "1rem",
																		}}
																	/>
																)}
																{permissions?.includes(
																	"admin.product.changeSatusall"
																) && (
																	<Switch
																		title='تغيير الحالة'
																		onChange={() =>
																			changeProductStatus(row?.id)
																		}
																		sx={switchStyle}
																		checked={
																			row?.status === "نشط" ? true : false
																		}
																	/>
																)}
																{permissions?.includes(
																	"admin.product.addNote"
																) && (
																	<SendNote
																		title='ارسال ملاحظة'
																		className='w-6 h-6 cursor-pointer'
																		onClick={() => {
																			openTraderAlert(row);
																		}}
																	/>
																)}
																<button
																	onClick={() => previewProductOnStore(row.url)}
																	disabled={
																		row?.status === "نشط" ? false : true
																	}>
																	<LinkArrow
																		className={`${
																			row?.status === "نشط"
																				? "cursor-pointer "
																				: "cursor-not-allowed opacity-80"
																		} w-5 h-5 `}
																		title={
																			row?.status === "نشط"
																				? "معاينة المنتج في المتجر"
																				: " المنتج غير نشط "
																		}
																	/>
																</button>
															</div>
														</TableCell>
														<TableCell align='center'>
															<h2
																className='md:text-[18px] text-[16px]  text-[#1948a0]font-normal'
																style={{ color: "#4D4F5C" }}>
																{moment(row.created_at).format("YYYY-MM-DD")}
															</h2>
														</TableCell>
														<TableCell align='right'>
															<div className='flex flex-row items-center justify-center '>
																<h2
																	style={{
																		color:
																			row?.admin_special === "مميز"
																				? "#3AE374"
																				: "#ADB5B9",
																	}}
																	className='md:text-[16px] text-[14px] min-w-[50px] whitespace-nowrap'>
																	{row?.admin_special}
																</h2>
																<Switch
																	onChange={() =>
																		changeProductSpecialStatus(row?.id)
																	}
																	className=''
																	sx={changeAllSwitch}
																	checked={
																		row?.admin_special === "مميز" ? true : false
																	}
																/>
															</div>
														</TableCell>
														<TableCell align='center'>
															<div className='flex flex-row items-center justify-end gap-3 min-h-[35px] max-h-[35px]'>
																{row?.activity?.length > 1 && (
																	<DynamicMenu items={row?.activity} />
																)}
																<h2
																	style={{
																		color: "#4D4F5C",
																		overflow: "hidden",
																		textOverflow: "ellipsis",
																		whiteSpace: "nowrap",
																		direction: "rtl",
																		width: "140px",
																	}}
																	className='md:text-[16px] text-[14px] inline whitespace-nowrap font-normal'>
																	{row?.store?.activity?.[0]?.name}
																</h2>
																{row?.activity?.[0]?.icon && (
																	<div
																		className='img_icons'
																		style={{
																			minWidth: "35px",
																			minHeight: "35px",
																			maxWidth: "35px",
																			maxHeight: "35px",
																		}}>
																		<img
																			src={row?.store?.activity?.[0]?.icon}
																			alt={row?.store?.activity?.[0]?.name}
																			loading='lazy'
																		/>
																	</div>
																)}
															</div>
														</TableCell>
														<TableCell align='center'>
															<div
																className='w-20 h-full py-[2px] rounded-2xl'
																style={{
																	backgroundColor:
																		row?.status === "نشط"
																			? "rgba(58, 227, 116, 0.4)"
																			: "#D3D3D3",
																	marginLeft: "auto",
																}}>
																<h2
																	className='md:text-[16px] text-[14px]'
																	style={{
																		color:
																			row?.status === "نشط"
																				? "#011723"
																				: "#b6b1c4",
																	}}>
																	{row?.status}
																</h2>
															</div>
														</TableCell>
														<TableCell align='right' style={{ width: "120px" }}>
															<div className='flex flex-row items-center justify-end gap-3'>
																<h2
																	className='md:text-[16px] text-[14px]'
																	style={{
																		color: "#4D4F5C",
																		overflow: "hidden",
																		textOverflow: "ellipsis",
																		whiteSpace: "nowrap",
																		direction: "rtl",
																		width: "120px",
																	}}>
																	{row?.store?.store_name}
																</h2>

																<div
																	className='img_icons'
																	style={{
																		minWidth: "35px",
																		minHeight: "35px",
																		maxWidth: "35px",
																		maxHeight: "35px",
																	}}>
																	<img
																		src={
																			row?.store?.logo
																				? row?.store?.logo
																				: PlaceholderImage
																		}
																		alt={row?.store?.store_name}
																		loading='lazy'
																		style={{
																			width: "100%",
																			height: "100%",
																			objectFit: "cover",
																			borderRadius: "50%",
																		}}
																	/>
																</div>
															</div>
														</TableCell>
														<TableCell align='right' className='max-w-[240px]'>
															<div className='flex flex-row items-center justify-end gap-3'>
																<h2
																	className='w-full md:text-[16px] text-[14px] font-normal overflow-hidden whitespace-nowrap'
																	style={{
																		color: "#4D4F5C",
																		cursor: "pointer",
																		textOverflow: "ellipsis",
																		direction: "rtl",
																	}}
																	onClick={() => {
																		if (
																			permissions?.includes(
																				"admin.product.show"
																			)
																		) {
																			openProductDetails(row?.id);
																		}
																	}}>
																	{row?.name}
																</h2>

																<div
																	className='img_icons'
																	style={{
																		minWidth: "35px",
																		minHeight: "35px",
																		maxWidth: "35px",
																		maxHeight: "35px",
																	}}>
																	<img
																		src={row?.cover}
																		alt={row?.name}
																		loading='lazy'
																	/>
																</div>
															</div>
														</TableCell>

														<TableCell padding='none' align={"right"}>
															<div className='flex items-center justify-end'>
																<div className='text-lg font-normal '>
																	{(index + 1).toLocaleString("en-US", {
																		minimumIntegerDigits: 2,
																		useGrouping: false,
																	})}
																</div>

																<Checkbox
																	checkedIcon={<CheckedSquare />}
																	sx={{
																		color: "#4875c2",
																		"& .MuiSvgIcon-root": {
																			color: "#ADB5B9",
																		},
																	}}
																	checked={isItemSelected}
																	onClick={(event) =>
																		handleClick(event, row?.id)
																	}
																	inputProps={{
																		"aria-labelledby": labelId,
																	}}
																/>
															</div>
														</TableCell>
													</TableRow>
												);
											}
										)
									)}
									{emptyRows > 0 && (
										<TableRow
											style={{
												height: 53 * emptyRows,
											}}>
											<TableCell colSpan={6} />
										</TableRow>
									)}
								</Fragment>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
			{!search && (
				<>
					<NewPagination
						pageNumber={pageNumber}
						setPageNumber={setPageNumber}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
						page_count={page_count}
					/>
				</>
			)}
			{actionDelete && (
				<DeleteOneModalComp handleDeleteSingleItem={handleDeleteSingleItem} />
			)}
			{notificationTitle && (
				<DeleteModalComp
					handleDeleteAllItems={productDeleteAllHandel}
					handleChangeAllItemsStatus={productChangeStatusAllByParamsHandel}
					handleChangeAllItemsSpecific={productChangeSpecialByParamsHandel}
				/>
			)}
		</Box>
	);
}
